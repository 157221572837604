.buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.secondaryBtn {
    background-color: rgba($color: #000000, $alpha: 0.2) !important;
    color: #000000 !important;
}

.greenText {
    color: #1d6f42;
    width: fit-content;
    text-decoration: none;
}

.greenText:hover {
    color: #0d3720;
}

.greenText:hover {
    cursor: pointer;
}

.fileInput {
    color: white;
    display: none;
}

.customFileUpload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
    border-radius: 0px 4px 4px 0px;
}

.fakeInput {
    border: 1px solid rgba(0,0,0,0.23);
    border-right: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
}