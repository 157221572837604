@import "../../../asset/scss/variables";

.primary {
    color: $primary !important;
}

.dialog-container {
    padding: 18px 26px 18px 26px;
    overflow: auto;
}

.pointer {
    cursor: pointer;
}

.reserved {
    fill: #FDF7F7;
    stroke: #D45A5B;
    stroke-width: 2px;
}

.selected {
    fill: #E8F2F9;
    stroke: #4A98CA;
    stroke-width: 2px;
}


.stickyTopLeft{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
}

.stickyBottomRight{
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.unavailable {
    fill: white;
    stroke: gray;
    stroke-width: 2px;
}

.free {
    fill: #F7FCF9;
    stroke: #B4E1BC;
    stroke-width: 2px;
}

.rectText {
    font-size: 12px;
    text-anchor: middle;
}

.btnClose {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.hitbox {
    transform-box: fill-box;
    transform-origin: center;
}