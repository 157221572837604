$teams-purple: rgb(98, 100, 167);
$text-opaque: rgba(0,0,0,0.8);

.background-image {
    max-width: 40vw !important;
}

.teams-purple {
    color: $teams-purple !important;
    border-color: $teams-purple !important;
    &:hover {
        color: white !important;
        background-color: $teams-purple !important;
        border-color: $teams-purple !important;
    }
}

.redirect-text {
    color: $text-opaque
}