@import "../../asset/scss/variables";

//COMMON REGION

.title{
    font-weight: 600;
    color: $primary
}

.desktop-modal-container, .mobile-modal-container{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-height: 30%;
    background-color: white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.info-text{
    color: #1E1E1E99;
}

.room-name, .shift-name{
    margin-bottom: 0;
}

.room-name{
    font-weight: 600;
}

.tab-btn{
    color: $primary !important;
}

.divider{
    width: 100%;
    border-bottom: solid $light-background-hover 2px;
}
//END COMMON REGION


//REGION DESKTOP
.img-desktop{
    height: 35vh;
}

.search-btn-desktop{
    background-color: $primary !important;
    height: fit-content;
    padding: 10px;
}


.pse-8-desktop{
    padding-right: 8rem !important;
    padding-left: 8rem !important; 
}

.desktop-modal-container{
    width: 60%;
}
//END REGION DESKTOP

//REGION MOBILE

.img-mobile{
    max-height: 12rem;
}

.drawer-puller{
    width: 30px;
    height: 6px;
    background-color: grey;
    border-radius: 3px;
    position: absolute;
    top: 8px;
    left: calc(50% - 15px)
}

.drawer-header-text{
    margin-top: 8px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 16px;
    text-align: center;
}

.drawer-content-container{
    overflow: auto;
    height: 100%;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
}

.drawer-header{
    background-color: white;
    position: absolute;
    top: -$drawerBleeding;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    visibility: visible;
    right: 0;
    left: 0;
}

.mobile-modal-container{
    width: 80%;
}

.mobile-modal-item{
    margin:auto;
    text-align: center;
}

//END REGION MOBILE