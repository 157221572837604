@import "./asset/scss/_variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAF9F8 !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bgc-primary {
  background-color: $primary !important;
}

.primary {
  color: $primary;
}

.primary-f {
  color: $primary !important;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected, .MuiSvgIcon-root.Mui-active, .MuiSvgIcon-root.Mui-completed, .MuiStepLabel-label.Mui-active, .MuiCheckbox-root.Mui-checked, .MuiSwitch-colorPrimary.Mui-checked {
  color: $primary !important;
}

.padding-custom {
  padding-left: 5px !important;
}

.MuiTabs-indicator {
  background-color: $primary !important;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary:not(.Mui-disabled), .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track, .swal2-confirm  {
  background-color: $primary !important;
}

.card-container{
  width: 180px !important;
}

.card-container-fullwidth{
  width: 100% !important;
}

.card-container, .card-container-fullwidth{
  min-height: 180px;
  min-width: 320px;
  border-radius: 13px;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .4);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 15px;
  position: relative;
  cursor: default;
  margin: 10px;
  & .card-title-container {
    width: 100%;
    & .card-title{
      color: $white;
      word-break: keep-all;
      font-size: 18px;
    }
    & .card-title-icon{
      color: white;
      font-size: 18px;
      margin-bottom: .5rem;
    }
  }
  & .card-subtitle-container {
    width: 100%;
    color: $white;
    word-break: keep-all;
    & .card-subtitle1{
      font-size: 18px;
    }
  }
  & .footer .card-subtitle1,
  & .card-subtitle1{
    color: $white;
    word-break: keep-all;
    font-size: 18px;
  }

  & .footer .card-subtitle2,
  & .card-subtitle2{
    color: $white;
    font-size: 14px;
  }
  & .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all ease-in-out .2s
  }
  & .footer-static {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  & .controls-container {
    position: relative;
    right: 20px;
  }
  .action-container{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2%;
    color: $white;
    font-weight: 500;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    overflow: hidden;
    transition: all ease-in-out .2s,  opacity ease .21s;
    background-color: rgba($color: #FF5052, $alpha: 1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    & span {
      font-size: 12px;
    }
  }
}

.card-container:hover{
  & .action-container{
    height: 20%;
    padding: 5px;
    opacity: 1;
  }

  & .footer {
    transform: translateY(-40px);
    transition-duration: 200ms;
  }
}

.submenu-separator{
  border-left: 1px solid #707070;
  margin: 0 10px;
  border-radius: 3px;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  min-width: 30vw;
  width: 30vw;
}

.MuiFormControlLabel-labelPlacementTop {
  margin: 0 !important;
}

.MuiModal-root.MuiDrawer-root.MuiDrawer-modal > .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

//Stili bottoni dialog message

.dialog-btn {
  border-radius: 0.25em !important;
  padding: 10px !important;
  font-weight: 600 !important;
  font-size: 1em !important;
}

.dialog-btn:hover {
  background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1)) !important
}

.dialog-dismiss, .swal2-cancel {
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  color: #000000 !important;
}

.dialog-accept {
  background-color: $primary !important;
  border: none !important;
}

@media (min-width: 1536px) {
  .user-grid .MuiGrid-root.MuiGrid-item {
    max-width: 24% !important;
  }
}

/* TRACKBAR */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}
::-webkit-scrollbar-thumb:active {
  background: #969696;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #e3e3e3;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.dynamic-text {
  white-space: break-spaces;
  word-break: break-all;
}

.table-hover-user:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.white {
  color: #FFFFFF !important;
}

.no-overflow .MuiDialog-paper.MuiDialog-paperScrollPaper {
  overflow-x: hidden !important;
}

#root {
  overflow-x: hidden !important;
}

.pointer {
  cursor: pointer;
}

.invisible {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}