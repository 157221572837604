.reportRow {
    border-bottom: 1px solid lightgray;
}

.reportRow > div > p:first-child{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.excelIcon{
    --fa-secondary-color: rgb(11, 90, 11);
    --fa-primary-color: rgb(224, 224, 224);
    --fa-secondary-opacity: 0.7;
    
}

.excelButton{
    background-color: transparent;
    border: none;
}

.footer{
    position:fixed;
    color: white;
    background-color: #0c5468;
    width: 100%;
    bottom:0;
    height:50px;
    padding: 10px 20px;
}

.footer-text{
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.5rem;
}

div > .user-check {
    height: 15px;
    margin-right: 5px;
    padding: 0 !important;
}