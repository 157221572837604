@import "../../../asset/scss/variables";

.container {
  background-color: white;
  border: 1px solid lightgray;
}

.reservationRow {
  border-top: 1px solid lightgray;
}

.link-text {
  color: $action;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.icon-primary {
  color: $primary !important;
}

.room-icon {
  background-color: transparent !important;
  color: #000000 !important;
}

.room-hitbox {
  min-width: 0px !important;
}