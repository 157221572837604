@import "../../../asset/scss/variables";

.note{
top: 415px;
left: 315px;
width: 35px;
height: 16px;
text-align: left;
font: normal normal 900 12px/29px Segoe UI;
letter-spacing: 0px;
color: #0C5468;
opacity: 1;

}
.msg{
text-align: left;
font: normal normal normal 20px/30px Segoe UI;
letter-spacing: 0px;
color: #0A0A0A;
opacity: 1;
}

.divPageMargin{
    margin: auto;
    width: 50%;
    margin-top: 3vh;
    margin-bottom: 3vh;
}
.paginationCenter{
    margin: auto;
    width: 60%;
}
.search-btn-desktop{
    background-color: $primary !important;
    height: fit-content;
    padding: 10px;
    margin-right: 2vh !important;
}