@import "../../asset/scss/variables";

.roomCard {
    color: white !important; 
    font-size: 16px !important;
    margin-bottom: 10px;
  
    min-height: 7rem;
    padding: 5px 0 5px 0;
    font-weight: 600;
    border-radius: 8px !important;
    background-size: cover;
    & p{
      word-break: break-word;
      font-weight: 500 !important;
    }
    // height:100%;
  }
  .bg-room {
    background-color: rgba($primary,0.7);
  }
.font-button{
    position: relative;
    left: 41%;
    bottom: 46px;
    background-color: rgba(12, 84, 104, 0.7);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    color:white;
}

.font-button-no-action{
    background-color: transparent;
    border: none;
    color:white;
    pointer-events: none;
}

.accordion-title{
    color: $primary;  
}

.button-overlay {
    width: 105px; 
}

.button-primary{
    color: white;
}

.button-secondary {
    background-color: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    color: #000000 !important;
}

.action-text {
    color: $action !important;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.h-70 {
    height: 70% !important;
}

.room-grid-item {
    padding-left: 20px !important;
    padding-top: 15px !important;
}

.shimmer::after {
    -webkit-animation: shimmering 1.6s linear 0.5s infinite;
    -moz-animation: shimmering 1.6s linear 0.5s infinite;
    -ms-animation: shimmering 1.6s linear 0.5s infinite;
    -o-animation: shimmering 1.6s linear 0.5s infinite;
    animation: shimmering 1.6s linear 0.5s infinite;
  
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  
    
    background: rgba(0, 0, 0, 0) linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.10), transparent) repeat scroll 0% 0%;
    z-index: 10;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  @-webkit-keyframes shimmering {
    0% { -webkit-transform: translateX(-100%); }
  
    50% { -webkit-transform: translateX(100%); }
  
    100% { -webkit-transform: translateX(100%); }
  }
  
  
  @-moz-keyframes shimmering {
    0% { -moz-transform: translateX(-100%); }
  
    50% { -moz-transform: translateX(100%); }
  
    100% { -moz-transform: translateX(100%); }
  }
  
  @-ms-keyframes shimmering {
    0% { -ms-transform: translateX(-100%); }
  
    50% { -ms-transform: translateX(100%); }
  
    100% { -ms-transform: translateX(100%); }
  }
  
  @-o-keyframes shimmering {
    0% { -o-transform: translateX(-100%); }
  
    50% { -o-transform: translateX(100%); }
  
    100% { -o-transform: translateX(100%); }
  }
  
  @keyframes shimmering {
    0% { transform: translateX(-100%); }
  
    50% { transform: translateX(100%); }
  
    100% { transform: translateX(100%); }
  }