
@import "../../../asset/scss/_variables.scss";

.modal-header {
    // background-color: $primary !important;
    height: 30%;
    position: relative;
    background-size: 100% 100% !important;
}

.modal-header::before{
    content: "";
    position:absolute;
    top:0;
    bottom: 0;
    right: 0;
    left:0;
    background-color: rgba($primary,0.85);
  }

.header-title {
    color: white;
    font-weight: bold;
}

.input-field {
    visibility: hidden;
    width: 0;
}

.carousel-dots{
    height: 50px;
    // position: relative;

    .dot-wrapper{
        margin:0;
        list-style-type: none;
        overflow: hidden;
        position: absolute;
        // left: 50%;
        // top: 50%;
        width: 100%;
        padding-left: 0 !important;
        // transform: translate(-50%, -50%);

        li{
            margin:0;
            float: left;
            margin-right: 10px;

            .dot{
                margin-left: 0.04rem;
                background: white;
                border-radius: 100%;
                width:13px;
                height:13px;
                min-width: auto;
                padding: 0;
                border: 1px solid grey;

            }

            &.selected{
                .dot{
                    background: $primary;
                }
            }

        }
    }



}

.btnCircle {
    position: absolute;
    right: 5px;
    width: 70px;
    height: 70px;
    padding: 15px 0px;
    border-radius: 50%;
    font-size: 24px;
    line-height: 1.33;

}



.reserveWeekBtn {
    background-color: $primary !important;
    height: 40px;
}

.reserveWeekBtnUnavailable {
    background-color: darkgray !important;
    height: 40px;
}

.reserveWeekText {
    color: white;
}

.drawerClass {
    z-index: 1300 !important;
}

.icon-btn:not(.Mui-disabled) {
    color: $primary;
}

.shift-text {
    font-size: 20px;
    font-weight: 300;
}

.close-btn {
    position: absolute !important;
    top: 0;
    left: 0;
}


.slide-caret {
    position: absolute;
    top: 50%;
    width: fit-content !important;
}

.left-caret {
    left: 5px;
}

.right-caret {
    right: 5px;
    display: flex;
    justify-content: flex-end;
}

.shimmer::after {
    -webkit-animation: shimmering 1.6s linear 0.5s infinite;
    -moz-animation: shimmering 1.6s linear 0.5s infinite;
    -ms-animation: shimmering 1.6s linear 0.5s infinite;
    -o-animation: shimmering 1.6s linear 0.5s infinite;
    animation: shimmering 1.6s linear 0.5s infinite;

    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);


    background: rgba(0, 0, 0, 0) linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.10), transparent) repeat scroll 0% 0%;
    z-index: 9999;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @-webkit-keyframes shimmering {
    0% { -webkit-transform: translateX(-100%); }

    50% { -webkit-transform: translateX(100%); }

    100% { -webkit-transform: translateX(100%); }
  }


  @-moz-keyframes shimmering {
    0% { -moz-transform: translateX(-100%); }

    50% { -moz-transform: translateX(100%); }

    100% { -moz-transform: translateX(100%); }
  }

  @-ms-keyframes shimmering {
    0% { -ms-transform: translateX(-100%); }

    50% { -ms-transform: translateX(100%); }

    100% { -ms-transform: translateX(100%); }
  }

  @-o-keyframes shimmering {
    0% { -o-transform: translateX(-100%); }

    50% { -o-transform: translateX(100%); }

    100% { -o-transform: translateX(100%); }
  }

  @keyframes shimmering {
    0% { transform: translateX(-100%); }

    50% { transform: translateX(100%); }

    100% { transform: translateX(100%); }
  }

.fav-btn {
    // position: absolute !important;
    top: 0;
    right: 8px;
    border-radius: 50% !important;
    // padding: 8px 12px !important;
    // border: 1px solid white !important;
    color: white !important;
    margin-top: 7px!important;
    z-index: 999 !important;
}


.link-text {
    font-weight: 500;
    color: $primary;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }

  .sa {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 140px;
    padding: 26px;
    background-color: #fff;
    &-success {
      border-radius: 50%;
      border: 4px solid $primary;
      box-sizing: content-box;
      height: 80px;
      padding: 0;
      position: relative;
      background-color: #fff;
      width: 80px;
      &:after, &:before {
        background: #fff;
        content: '';
        height: 120px;
        position: absolute;
        transform: rotate(45deg);
        width: 60px;
      }
      &:before {
        border-radius: 40px 0 0 40px;
        width: 26px;
        height: 80px;
        top: -17px;
        left: 5px;
        transform-origin: 60px 60px;
        transform: rotate(-45deg);
      }
      &:after {
        border-radius: 0 120px 120px 0;
        left: 30px;
        top: -11px;
        transform-origin: 0 60px;
        transform: rotate(-45deg);
        animation: rotatePlaceholder 4.25s ease-in;
      }
      &-placeholder {
        border-radius: 50%;
        border: 4px solid $primary;
        box-sizing: content-box;
        height: 80px;
        left: -4px;
        position: absolute;
        top: -4px;
        width: 80px;
        z-index: 2;
      }
      &-fix {
        background-color: #fff;
        height: 90px;
        left: 28px;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 5px;
        z-index: 1;
      }
      &-tip, &-long {
        background-color: $primary;
        border-radius: 2px;
        height: 5px;
        position: absolute;
        z-index: 2;
      }
      &-tip {
        left: 14px;
        top: 46px;
        transform: rotate(45deg);
        width: 25px;
        animation: animateSuccessTip .75s;
      }
      &-long {
        right: 8px;
        top: 38px;
        transform: rotate(-45deg);
        width: 47px;
        animation: animateSuccessLong .75s;
      }
    }
  }

  @keyframes animateSuccessTip {
    0%, 54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }
  @keyframes animateSuccessLong {
    0%, 65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
  @keyframes rotatePlaceholder {
    0%, 5% {
      transform: rotate(-45deg);
    }
    100%, 12% {
      transform: rotate(-405deg);
    }
  }