.buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.secondaryBtn {
    background-color: rgba($color: #000000, $alpha: 0.2) !important;
    color: #000000 !important;
}

.site-label {
  pointer-events: none !important;
}