@import "../../../asset/scss/variables";

.dialog-title{
    color: $primary;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 20px;
}

.content-bottom{
    margin: auto 0;
}

.bottom-title{
    margin-top: 5px;
    letter-spacing: 0px;
    color: #0A0A0A;
    opacity: 1;
}

.bottom-subtitle{
    font-weight: 300;
    letter-spacing: 0px;
    color: #0A0A0A;
    opacity: 1
}

.divider {
	margin: 0;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-width: 0;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.12);
	border-bottom-width: thin;
}

.dismiss-btn{
    width: 120px;
    text-transform: none !important;
    line-height: 1.5 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    letter-spacing: 0px !important;
}

.accept-btn{
    color: white !important;
    box-shadow: none;
    width: 120px;
    text-transform: none !important;
    line-height: 1.5 !important;
    letter-spacing: 0px !important;

}

.submsg-container{
    margin-top: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #0A0A0A;
    opacity: 1
}

.dialog-container{
    padding: 18px 26px 18px 26px
}

.dialog-content{
    padding-bottom: 16px;
    flex: 1 1 auto;
    color:black !important
}

.dialog-action{
    gap: 10px;
    display: flex;
    justify-content: flex-start ;
    margin-left: 0;
    margin-right: 0;
}