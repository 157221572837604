@import "../../../asset/scss/variables";

.container {
    background-color: #FAF9F8;
    flex-direction: column;
}

.textPrimary {
    color: $primary;
}

.subtitle {
    color: $primary;
    width: 50vw;
    text-align: center;
    font-weight: 400;
}

.bgWhite {
    background-color: white;
}

.imgClass {
    max-width: 100%;
    max-height: 55vh;
    object-fit: fill;
    position: relative;
    z-index: 10;
}

.cursorPointer {
    cursor: pointer;
}

.startBtn {
    background-color: transparent;
    border: 1px solid black;
    border-radius: 4px;
    padding: 10px;
    padding-inline: 20px;
}

.shadow {
    max-height: 60vw;
    // box-shadow: 0px 0px 5px 1px rgba(137, 137, 137, 0.5);
    box-shadow: 0px 1px 3px rgba($color: #000000, $alpha: 0.25);
    ;

}

.btnActive {
    background-color: $primary;
    color: white;
}

.loading-placeholder {
    height: 55vh;
    width: 45vw;
}

input.zoom-check[type=checkbox] {
    display: none;
}

.media-container img {
    // margin: 100px;
    transition: transform 0.25s ease;
    cursor: zoom-in;
}

input.zoom-check[type=checkbox]:checked~label>img {
    transform: scale(1.75);
    cursor: zoom-out;
}

.tutorial-text {
    max-width: 40vw;
    text-align: center;
}