@import "../../asset/scss/_variables.scss";
$selected-col: #f1f1f1;

.close-btn {
    position: absolute !important;
    top: 0;
    right: 0;
    border-radius: 4px !important;
    padding: 8px 12px !important;
    color: white !important;
    border: 1px solid white !important;
    margin: 2% !important;
    z-index: 999 !important;
}

.sticky {
  position: sticky!important;
  position: -webkit-sticky!important;
  top: 0!important;
  z-index: 1;
  background-color: #FAF9F8;
}

.borderforscroll {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.fav-btn {
    position: absolute !important;
    top: 0;
    right: 100px;
    border-radius: 4px !important;
    padding: 8px 12px !important;
    border: 1px solid white !important;
    color: white !important;
    margin: 2% !important;
    z-index: 999 !important;
}

.todayLabel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    margin-left: 0;
    color: $primary;
    flex-wrap: nowrap;
    font-weight: 500 !important;
}

.today-container {
    position: relative;

}

.header-box {
    display: flex;
    flex-wrap: nowrap;
    background-color: $primary;
    background: $primary 0% 0% no-repeat padding-box;
    color: white;
    // min-height: 26vh;
    // height: 20%;
    position: relative;
    // background-size: 100% 26vh !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    background-position: center !important;
}

.tit-1{
    margin: 1% auto auto auto;
    text-align: center;
    font: normal normal 600 250% Segoe UI;
}

.tit-2{
    text-align: center;
    font: normal normal 600 250% Segoe UI;
}

.sub-1, .more-details{
    text-align: center;
    font: normal normal normal 100% Segoe UI;
    letter-spacing: 0px;
    color: white
}

.more-details{
    cursor: pointer;
    text-decoration: underline;
}

.header-box::before{
    content: "";
    position:absolute;
    top:0;
    bottom: 0;
    right: 0;
    left:0;
    background-color: rgba($primary,0.85);
  }

.reserve-btn {
    // max-height: 80px;
    padding-bottom: 9%;
    margin-top: 8% !important;
}

.booked:hover {
    background-color: rgba(50,118,161,255) !important;
    color: "#FFFFFF" !important;
}

.free:hover {
    background-color: rgba(136,189,145,255) !important;
    color: "#FFFFFF" !important;
}

.full {
    pointer-events: all !important;
}

.full:hover {
    background-color: rgba(212,90,92,255) !important;
    cursor: default !important;
}

.past:hover {
    background-color: #bdbdbd !important;
    cursor: default !important;
}

.slide-caret {
    position: absolute;
    top: 50%;
}

.reserve-table {
    border-spacing: 0;
    border-collapse: collapse;
    contain: paint;
    z-index: 1;
}

.table-body {
    border-top: 0 !important;

    & td, th {
        cursor: pointer;
        padding: 10px;
        position: relative;
    }

    td:hover::after {
        background-color: $selected-col;
        content: '\00a0';
        height: 10000px;
        left: 0;
        position: absolute;
        top: -5000px;
        width: 100%;
        z-index: -1;
    }

    tr:nth-last-child(1){
        border-bottom: 0 solid transparent !important;
    }
}

.separator {
    width: 100px;
}

.separator-text {
    background-color: $selected-col;
    margin-top: 1.5px;
    padding: 0 5px;
}

.avoid-border {
    border: none !important;
    border-color: transparent !important;
}

.bookall-text {
    font-size: 14px;
    color: $action;
    text-decoration: none;
    margin-bottom: 0;
    cursor: pointer;
}

.disabled {
    color: #949494 !important;
    margin-bottom: 0;
}

.reserve-all:hover p {
    color: #0a58ca;
}

.grid-box{
    height: 20%;
    width: 300%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 9px;
}

.shimmer::after {
    -webkit-animation: shimmering 1.6s linear 0.5s infinite;
    -moz-animation: shimmering 1.6s linear 0.5s infinite;
    -ms-animation: shimmering 1.6s linear 0.5s infinite;
    -o-animation: shimmering 1.6s linear 0.5s infinite;
    animation: shimmering 1.6s linear 0.5s infinite;

    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);


    background: rgba(0, 0, 0, 0) linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.10), transparent) repeat scroll 0% 0%;
    z-index: 9999;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @-webkit-keyframes shimmering {
    0% { -webkit-transform: translateX(-100%); }

    50% { -webkit-transform: translateX(100%); }

    100% { -webkit-transform: translateX(100%); }
  }


  @-moz-keyframes shimmering {
    0% { -moz-transform: translateX(-100%); }

    50% { -moz-transform: translateX(100%); }

    100% { -moz-transform: translateX(100%); }
  }

  @-ms-keyframes shimmering {
    0% { -ms-transform: translateX(-100%); }

    50% { -ms-transform: translateX(100%); }

    100% { -ms-transform: translateX(100%); }
  }

  @-o-keyframes shimmering {
    0% { -o-transform: translateX(-100%); }

    50% { -o-transform: translateX(100%); }

    100% { -o-transform: translateX(100%); }
  }

  @keyframes shimmering {
    0% { transform: translateX(-100%); }

    50% { transform: translateX(100%); }

    100% { transform: translateX(100%); }
  }

  .link-text {
    font-weight: 500;
    color: $primary;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: $primary;
    }
  }

  .sa {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 140px;
    padding: 26px;
    background-color: #fff;
    &-success {
      border-radius: 50%;
      border: 4px solid $primary;
      box-sizing: content-box;
      height: 80px;
      padding: 0;
      position: relative;
      background-color: #fff;
      width: 80px;
      &:after, &:before {
        background: #fff;
        content: '';
        height: 120px;
        position: absolute;
        transform: rotate(45deg);
        width: 60px;
      }
      &:before {
        border-radius: 40px 0 0 40px;
        width: 26px;
        height: 80px;
        top: -17px;
        left: 5px;
        transform-origin: 60px 60px;
        transform: rotate(-45deg);
      }
      &:after {
        border-radius: 0 120px 120px 0;
        left: 30px;
        top: -11px;
        transform-origin: 0 60px;
        transform: rotate(-45deg);
        animation: rotatePlaceholder 4.25s ease-in;
      }
      &-placeholder {
        border-radius: 50%;
        border: 4px solid $primary;
        box-sizing: content-box;
        height: 80px;
        left: -4px;
        position: absolute;
        top: -4px;
        width: 80px;
        z-index: 2;
      }
      &-fix {
        background-color: #fff;
        height: 90px;
        left: 28px;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 5px;
        z-index: 1;
      }
      &-tip, &-long {
        background-color: $primary;
        border-radius: 2px;
        height: 5px;
        position: absolute;
        z-index: 2;
      }
      &-tip {
        left: 14px;
        top: 46px;
        transform: rotate(45deg);
        width: 25px;
        animation: animateSuccessTip .75s;
      }
      &-long {
        right: 8px;
        top: 38px;
        transform: rotate(-45deg);
        width: 47px;
        animation: animateSuccessLong .75s;
      }
    }
  }

  @keyframes animateSuccessTip {
    0%, 54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }
  @keyframes animateSuccessLong {
    0%, 65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
  @keyframes rotatePlaceholder {
    0%, 5% {
      transform: rotate(-45deg);
    }
    100%, 12% {
      transform: rotate(-405deg);
    }
  }