@import "../../../asset/scss/variables";

.modal-title{
    font-size: 24px;
    color: $primary;
    font-weight: 600;
}

.modal-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    height:75%;
    background-color: white;
    // bgcolor: "white",
    border-radius:5px;
    box-shadow: 10px;
    padding: 25px;
}

.modal-content{
    height: 80%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.table-body{
    height: 35%;
    overflow-y: scroll;
}

.content-title{
    font-size: 20px;
    font-weight: 600;
}

ul.list-dashed {
    list-style-type: none;
}

ul.list-dashed > li {
    text-indent: -5px;
}

ul.list-dashed > li:before {
    content: "-";
    text-indent: -5px;
}

.tab-container{
    height:25%;
    flex: 1;
}

.tab{
    position:relative;
    max-height: 100%;
    overflow-y: auto;
}

.search-btn-desktop{
    background-color: $primary !important;
    height: fit-content;
    padding: 10px;
    margin-right: 2vh !important;
}
.divPageMargin{
    margin: auto;
    width: 50%;
    margin-top: 3vh;
    margin-bottom: 3vh;
}
.paginationCenter{
    margin: auto;
    width: 60%;
}