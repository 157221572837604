@import "../../../asset/scss/variables";

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
}

.container {
    position: relative;
    left: 30%;
    width: 40%;
    top: 10%;
    height: 80%;
    background-color: white;
    padding: 2%;
    border-radius: 4px;
    overflow-y: hidden;
}

.container2 {
    width: 100%;
    height: 90%;
    background-color: white;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 10%;
}

.btnClose {
    color: $primary;
}

.textPrimary {
    color: $primary;
}

.imgClass {
    width: 100% !important;
    border-radius: 4px;
    max-height: 100%;
    height: 300px;
    object-fit: cover;
}

.fixedHeader {
    position: fixed;
    top: 12%;
    left: 32%;
    background-color: white;
    height: 10%;
    width: 36% !important;
}

.hr {
    border: 1.5px solid $primary;
    opacity: 1;
}

.closeDiv {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.wordBreak {
    word-break: break-all;
}

.userguide-description {
    max-height: 20vh;
    overflow-y: auto;
}