@import "../../../asset/scss/variables";

.icon-image {
    max-width: 18vw;
}

.container{
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);    
}

.title{
    color: $primary;
    font-weight: 600;
}