@import "../../../asset/scss/variables";

.alert{
    border: 3px solid rgb(243, 183, 183);
    border-radius: 3%;
}

.userX{
    padding-top: 14%;
    font-size: 16px !important;
}

.icon-image {
    max-width: 20vw;
}

.title{
    font-weight: 600;
}

.subtitle{
    font-size:larger;
    font-weight: 500;
}

.text-btn{
    color: $primary !important;
    font-weight: 500 !important;
}