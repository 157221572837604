@import "../../asset/scss/variables";

.users-footer {
    position: fixed;
    width: 100vw;
    bottom: 0;
    background-color: $primary;
    color: #FFFFFF;
    height: 2rem !important;
}

.syncButton {
    background-color: $primary !important;
    color: white !important;
}

.syncClicked {
    color: $icon-text-grey !important;
    background-color: lightgray !important;
}

.syncText {
    font-size: 12px;
    font-weight: 300;
}