
//Light - theme
$light-background-hover: rgba(0, 0, 0, .1);
$primary: #0C5468;
$action: #0078D7;
$purple: #464775;
$white: #ffffff;
$blue-action: rgba(0, 120, 215, 1);

$icon-text-grey: rgba(0, 0, 0, .4);
$text-danger: #CB3234;
$text-default: rgba(0, 0, 0, .7);

$drawerBleeding: 50px;