@import '../../../asset/scss/variables';

.section-title{
    color: $text-default;
}

.day-text {
    color: $text-danger;
}

.link-text {
    color: #1C7EBD;
    font-weight: lighter;
    cursor: pointer;
}

.left-margin {
    margin-left: -50px;
}