@import "../../asset/scss/variables";

.accordion-title {
    color: $primary;  
}

.button-primary {
    color: white;
}

.user-name {
    font-size: 17px !important;
}

.siteName, .siteNameFirst{
    font-weight: bold;
    color: $primary;
    font-size: large;
    margin-bottom: 4px;
}

.siteName{
    margin-top: 16px;
}

.siteCell{
    border-bottom: none !important;
}