.alert{
    border: 3px solid rgb(243, 183, 183);
    border-radius: 3%;
}

.userX{
    padding-top: 14%;
    font-size: 16px !important;
}

.icon-image {
    max-width: 18vw;
}