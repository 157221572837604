.date-step > div {
    width: 100% !important;
    // padding-right: 0.5rem !important;
}

.button{
    margin-top: 8px !important;
    margin-right: 8px !important;
}

.stepperBox{
    margin-bottom: 16px !important;
    padding-top: 24px !important;
}