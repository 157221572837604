@import "../../../asset/scss/variables";

.borderButton {
  border: 1px solid lightgray !important;
}

.site-label {
  pointer-events: none !important;
}
.sizeinput{
    height: 40px;
    width: 200px;
    margin-right: 3vh;
}

.borderforreservations {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
  margin-left:0px !important;
  width: 540px;
}
.labelmarginright{
  margin-right:auto !important;
}
.disabledmenuoption{
  pointer-events: none;
  opacity:0.6;
}
.belowDays{
  font-size: 12;
  display: inline-block;
  margin-top: 10px;
}
.circleYellow {
  display: flex;
  width: 13px;
  height: 13px;
  background-color: yellow;
  border-radius: 50%;
  margin-top:5px;
  margin-right:5px;
}

.circleRed {
  display: flex;
  width: 13px;
  height: 13px;
  background-color: red;
  border-radius: 50%;
  margin-top:5px;
  margin-right:5px;
}

.form{
  margin-top: 2px;
  overflow-y: scroll;
  height: 40vh;
  width: 100%;
}

.DaysCovered{
  color: $primary;
}