@import "../../../asset/scss/variables";

.primary {
    color: $primary !important;
}

.gray {
    color: lightgray;
}

.red {
    color: red;
}

.green {
    color: limegreen;
}

.dialog-container-mobile {
    padding: 25px 26px 25px 26px
}

.dialog-container-desktop {
    padding: 25px 45px 25px 45px
}

.seat-name, .reserved-by {
    font-weight: 600;
}

.seat-col {
    &.free {
        &:hover {
            cursor: pointer;
            background-color: #D2FFDA54;
        }
        & .reservation-subtitle > p {
            color: #00B421;
        }
    }
    &.reserved {
        background-color: #00000008;
        & .seat-name > p {
            color: #161616B3
        }
        & .reservation-subtitle > p {
            color: #8D8D8D;
        }
    }
    &.reserved-me {
        background-color: #1C7EBD17;
        & .reservation-subtitle > p {
            color: $primary;
        }
    }
}

.title{
    color: $primary;
    height: fit-content;
}