.menu-header{
    height: 10%;
}

.menu-body{
    height:75%;
}

.title{
    color:#0c5468;  
    margin-bottom: 0;
}
  
.column-left {
    float: left;
    padding-right: 10px;
    width: 50%;
}

.column-right {
    float: left;
    padding-left: 10px;
    width: 50%;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.button-overlay {
    width: 105px !important; 
}

.button-primary{
    color: white; 
}

.button-secondary {
    background-color: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    color: #000000 !important;
}

@media only screen and (max-width: 800px) {
    .shiftNameF{
        padding-bottom: 3%;
    }
}

.img-desc{
    font-size: small;
    font-style: italic;     
    margin-bottom: 4px;
    color: gray;
}

.img-center{
    position:absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius:5px;
    box-shadow: 10;
}

.font-button{
    background-color: transparent;
    border: none;
    color:black;
    padding-bottom: 5px;
}

