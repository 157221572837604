@import "../../../asset/scss/_variables.scss";
.toolbar {
  padding: 0;
  align-items: center;
  margin: 0 !important;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 2;
  justify-content: flex-end;
  width: 100%;

  & .p-20-custom {
    padding: 20px 20px 20px 30px;
  }

  & .menu-button {
    font-size: 20px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    border-radius: 50%;
    padding: 10px 15px;
  }
  & .menu-button:hover {
    background-color: $light-background-hover;
  }

  & .logo-toolbar {
    // width: 100%;
    width: 60px !important;
    // height: 72px;
    // margin-left: -90px;
  }

  & .svg-icon {
    font-size: 3.5rem !important;
  }

  & .submenu-separator {
    border-left: 0.9px solid #707070;
    margin: 0 19.5px;
    border-radius: 3px;
  }

  & .txtmenu-link+.icon-section {
    margin-left: 0 !important;
  }


  & .menu-link {
    font-size: 20px !important;
    text-transform: none !important;
    text-decoration: none !important;
    font-family: unset;
  }
  & .txtmenu-link {
    font-weight: 500 !important;
    font-size: 20px !important;
    text-transform: none !important;
    text-decoration: none !important;
    font-family: unset;

    cursor: pointer;
  }

  & .submenu-link {
    color: $primary !important;
    text-decoration: none !important;
    text-transform: none !important;
    text-decoration: none !important;

  }
  & .submenu-link.active {
    text-decoration: underline;
  }

  & .icon-section {
    // margin-left: 10px;
    display: flex;
    padding: 5px 0 5px 0;

    & .toolbar-icon {
      font-size: 26px;
      margin: 0 10px 0 10px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }

    & .icon-reservation {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(30, 30, 30, 0.6);
      font-size: 16px;
      height: 27px;
      width: 27px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
    }

    & .last-separator {
      margin-right: 10px !important;
    }
  }

  & .icon-section {
    // margin-left: 10px;
    display: flex;
    padding: 4px 0 4px 0;
  }

  .burger {
    font-size: 32px;
  }
}

.alignLeft,
.alignLeftDemo {
  margin-right: auto;
}

.alignLeftDemo a {
  color: $primary !important;
}

.alignLeftDemo p {
  font-weight: 500;
}

.settingIcon {
  color: rgba(0, 0, 0, 0.4);
  font-size: 19px !important;
}

.menu-mobile a, .menu-mobile h3, .menu-mobile svg, .menuLinkMobile {
  color: $primary;
  text-decoration: none;
}

.menu-mobile h3 {
  font-size: 30px;
}

.menuLinkMobile > h4 {
  font-size: 30px;
  font-weight: 400;
}

.logo-mobile {
  padding: 15px;
}

.logo-toolbar-mobile {
  width: 4.8rem !important;
}

.messageContainer{
  background-color: #FFECCB;
  padding: 5px;


}

.messageContainer > p{
  color: #575757;
  font-size: 14px;

  > strong > a {
    color: #575757 !important;

    &:hover{
      color: black !important;
    }
  }
}

.guideList {
  position: absolute;
  z-index: 3;
  background-color: white;
  width: 22% !important;
  border-radius: 3px;
  right: 4%;
  margin-top: 20%;
  box-shadow: 0px 0px 3px 0px rgba(#000000, 0.2);
  & a:hover, span:hover {
    color: $primary;
    cursor: pointer;
  }
}

.tutorialsLink {
  color: black;
  text-decoration: none;
}

.tutorialsLink:hover {
  color: black;
}

.listElement {
  cursor: pointer;
}