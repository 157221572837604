@import "../../../asset/scss/variables";

.roomCard {
  color: white !important;
  font-size: 16px !important;
  margin-bottom: 10px;

  min-height: 8rem;
  padding: 5px 0 5px 0;
  font-weight: 600;
  border-radius: 8px !important;
  background-size: cover;

  & p {
    word-break: break-word;
  }

  // height:100%;
}

.bg-room {
  background-color: rgba($primary, 0.7);
}

.font-button-no-action {
  background-color: transparent;
  border: none;
  color: white;
  pointer-events: none;
}

.tooltip-room {
  width: "400px";
  height: "300px";
  border-radius: 8px;
  //background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.roomname-tooltip {
  width: 100% !important;
  // height: 200px !important;
  border-radius: 0px 0px 8px 8px !important;
  padding: 8px;
  color: white !important;
  background-color: $primary  !important;
  font-size: 16px !important;

  & p {
    word-break: break-word;
    font-weight: 500 !important;
  }

  align-items: center;
}

.shimmer::after {
  -webkit-animation: shimmering 1.6s linear 0.5s infinite;
  -moz-animation: shimmering 1.6s linear 0.5s infinite;
  -ms-animation: shimmering 1.6s linear 0.5s infinite;
  -o-animation: shimmering 1.6s linear 0.5s infinite;
  animation: shimmering 1.6s linear 0.5s infinite;

  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);


  background: rgba(0, 0, 0, 0) linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.10), transparent) repeat scroll 0% 0%;
  z-index: 9999;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@-webkit-keyframes shimmering {
  0% { -webkit-transform: translateX(-100%); }

  50% { -webkit-transform: translateX(100%); }

  100% { -webkit-transform: translateX(100%); }
}


@-moz-keyframes shimmering {
  0% { -moz-transform: translateX(-100%); }

  50% { -moz-transform: translateX(100%); }

  100% { -moz-transform: translateX(100%); }
}

@-ms-keyframes shimmering {
  0% { -ms-transform: translateX(-100%); }

  50% { -ms-transform: translateX(100%); }

  100% { -ms-transform: translateX(100%); }
}

@-o-keyframes shimmering {
  0% { -o-transform: translateX(-100%); }

  50% { -o-transform: translateX(100%); }

  100% { -o-transform: translateX(100%); }
}

@keyframes shimmering {
  0% { transform: translateX(-100%); }

  50% { transform: translateX(100%); }

  100% { transform: translateX(100%); }
}

.bordi-tondi {
  border-radius: 8px !important;
  & .rounded-img {
    border-radius: 8px 8px 0 0;
  }
  & .rounded-div, .rounded-p {
    border-radius: 0px 0px 8px 8px;
  }
}

.fav-btn {
  display: flex !important;
  justify-content: flex-end !important;
  width: 100% !important;
}

.star-icon {
  background-color: #043341;
  border-radius: 50%;
  padding: 2.5px;
}

.h-70 {
  height: 70% !important;
}

.divider {
  height: 2px;
  border-width: 0;
  color: $primary;
  background-color: $primary;
}