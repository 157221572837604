@import "../../asset/scss/variables";

.title{
    font-weight: 600;
    color: $primary;
    text-align: center;
}

.setted{
    display: inline;
    float: right;
    margin-right: 5vh;
    margin-bottom: 0px;
    padding-top: 8px;
}

.limitedReservationCard{
    padding-right: 30px;
    padding-top: 52.7vh;
}

.paddingforprofiles {
    padding-left: 20px;
}

.borderforprofiles {
    padding-top: 1px;
    padding-bottom: 1px;
    border-bottom: 1px solid #dee2e6;
}
.sizeinput{
    height: 40px;
}

.sizeinputSetted{
    margin-left: 10px;
    margin-top: 20px;
    height: 40px;
    width: 250px;
}

.divinputDays{
    margin-left: 10px;
    margin-right: 10px;
    margin-top:20px;
}
.sizeinputDays{
    height: 40px;
    width: 250px;
}