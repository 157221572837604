@import "../../asset/scss/variables";

.container{
    justify-content: center;
    display: flex;
}
.mainText{
    margin-top: -3%;
    font-family: 'Segoe UI';
    font-weight: 620 ;
    text-align: center;
    letter-spacing: -0.4px;
    font-size: 220%;
    color: #0C5468;
}
.SecondaryText{
    margin-top: -0.5%;
    font-family: 'Segoe UI';
    font-weight: 400;
    letter-spacing: -0.4px;
    text-align: center;
    font-size: 150%;
    color: #0A0A0A;
}
.imageUpload{
    height: 40%;
}
.btn{
    text-align: center;
    margin-top: 3.5%;
}
.fstTxtDimensions{
    margin-top: 0.5%;
    font-family: 'Segoe UI';
    font-weight: light;
    font-style: italic;
    text-align: center;
    letter-spacing: -0.2px;
    font-size: 70%;
    color: #474747;
}
.scndTxtDimensions{
    margin-top: -1.2%;
    font-family: 'Segoe UI';
    font-weight: light;
    font-style: italic;
    text-align: center;
    letter-spacing: -0.2px;
    font-size: 70%;
    color: #474747;
}
.txtAntiprima{
    font-family: 'Segoe UI';
    font-weight:500;
    margin-bottom: -0.8%;
    letter-spacing: -0.2px;
    font-size: 110%;
    color: #767575;
}

.grey-btn{
    color:#0A0A0A !important;
    background-color: #D6D6D6 !important;
}

.blue-btn, .grey-btn{
    padding: 0.6% 3% 0.6% 3%;
}