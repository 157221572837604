@import '../../asset/scss/variables';

.settings-container{
    margin: 15px 1rem;
    & h2 {
        padding: 0 1rem
    }

    & .settings-row{
        padding: 0 1rem;
        & .setting-title{
            font-weight: 400;
        }
        & .lang-box{
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            opacity: .5;
            padding: 10px;
            border: .5px solid rgba($color: #000000, $alpha: .4);
            margin: 5px 10px;
            transition: all ease-in-out .2s;
        }
        & .lang-box.pointer{
            cursor: pointer;
        }
        
        & .lang-box.active{
            color: $primary;
            border-color: $primary;
            opacity: 1;
            background: linear-gradient(90deg, rgba($color: $primary, $alpha: 0.03), transparent, transparent, transparent, rgba($color: $primary, $alpha: .03));
            box-shadow: 0 0 2px -1px rgba(0, 0, 0, .3), 0 0 5px -1px rgba(0, 0, 0, .2);
        }
        & .lang-box:hover{
            box-shadow: 0 0 2px -1px rgba(0, 0, 0, .3), 0 0 3px -1px rgba(0, 0, 0, .2);
            opacity: 1;
        }
    }
}

.version {
    position: absolute;
    bottom: 5px;
    text-align: end;
    right: 15px;
}