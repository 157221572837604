@import "../../asset/scss/variables";

.title{
    font-weight: 600;
    color: $primary
}
.desktop-modal-container, .mobile-modal-container{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-height: 30%;
    background-color: white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
div.emailText {
    font-size: 12px;
    font-weight: 300;
}
.sizeinput{
    height: 40px;
}
.info-text{
    color: #1E1E1E99;
}

.room-name, .shift-name{
    margin-bottom: 0;
}

.room-name{
    font-weight: 600;
}

.tab-btn{
    color: $primary !important;
}
.divseparatedatapicker{
    margin-right:16px
}
.sizecard{
    max-height: 75vh;
}
.borderforusers {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
}
.borderforselectall {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    border-bottom: 1px solid #dee2e6;
}
.divider{
    width: 100%;
    border-bottom: solid $light-background-hover 2px;
}

.img-desktop{
    height: 35vh;
}

.search-btn-desktop{
    background-color: $primary !important;
    height: fit-content;
    padding: 10px;
    margin-top: 10px !important;
}


.pse-8-desktop{
    padding-right: 8rem !important;
    padding-left: 8rem !important;
}

.desktop-modal-container{
    width: 60%;
}
