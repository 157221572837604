@import "../../asset/scss/variables";

.primary {
    color: $primary;
}

.btnGuide {
    background-color: white;
    color: $primary;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(#000000, 0.29);
}

.btnActive {
    background-color: $primary;
    color: white;
}

.groupLabel {
    background-color: $primary;
    color: white;
    padding-inline: 5px;
    padding: 2px 14px;
    border-radius: 17px;
    font-size: 11px;
}

.guide-name {
    font-weight: 500;
}

.guide-row:hover {
    background-color: rgba(#1b1b1b, 0.03);
    cursor: pointer;
}