.title{
    color:#0c5468;  
    margin-bottom: 0;
    flex: none;
}

.button-secondary {
    background-color: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    color: #000000 !important;
}

.subtitle {
    font-size: 12px;
}

.footer {
    flex: none
}

.divider {
    height: 1px !important;
    width: 100% !important;
    background-color: lightgray !important;
}

.tab-content {
    flex: auto;
    display: flex;
    flex-direction: column;
}

.no-flex {
    flex: none;
}

.auto-flex {
    flex: auto;
}

.table-col {
    overflow-y: auto;
}