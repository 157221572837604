.loader {
    margin: auto;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000002;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.bg-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000001;
}